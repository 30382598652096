<template>
	<div class="box box_mm">
		<div class="box_main">
			<search @search="search" @reset="reset" ref="mysearch" url="/api/proposa/index" :hiddentype="true" :hiddenhb="true">
							
			</search>
			<div class="box_cont">
				<div class="btn_list">
					<!-- <el-button type="primary" @click="goaddnew">新增</el-button> -->
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showselect="selecthe"
						:showbm="true" :czwidth="100" @getdata="gettable" @selectdata="selectdata">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="look(scope.row)" type="success" size="mini">详情</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>


		<!--详情-->
		<mydetail v-if="showdetail" :id="detail_id" :showdy="true" requsturl="/api/proposa/details"
			@closedetail="showdetail=false">
		</mydetail>
	</div>
</template>

<script>	
	import mydetail from "@/components/detail_tc.vue";
	import search from '../component/search';
	export default {
		components: {
			mydetail,
			search
		},
		data() {
			return {
				showdetail: false,
				detail_id: '',
				searchinput: '',
				tableHeader: [{
						name: '提案号',
						prop: 'code',
						width: '80'
					},
					{
						name:'流水号',
						prop:'lscode',
						width:'80'
					},
					{
						name: '标题',
						prop: 'name',
						width: '300'
					},
					{
						name: '提案类别',
						prop: 'lei_name',
						width: ''
					},
					{
						name: '是否为合并提案',
						prop: 'zid',
						width: '130',
						type: 'zid_tbn'
					},
					{
						name: '提交人',
						prop: 'user_name',
						width: ''
					},
					{
						name: '提交时间',
						prop: 'addtime',
						width: ''
					},
					{
						name: '状态',
						prop: 'type',
						width: '120',
						type: 'tags'
					},
					{
						name: '处理异议',
						prop: 'type',
						width: '120',
						type: 'tags_yy'
					}
				],
				tableData: [],
				
			}
		},
		mounted() {
			this.$refs.mysearch.searyjstype='3'
			this.gettable()


		},
		activated() {
		    this.gettable(); // 每次进入页面时刷新数据
		},
		methods: {
			selectdata(row) { //选择
				if (row.zid) {
					this.$message({
						type: "warning",
						message: "不能选择已合并过的提案",
					})
					this.gettable()
					return false
				}
				if (row.type != 1) {
					this.$message({
						type: "warning",
						message: "只有未审核的提案才可以合并",
					})
					this.gettable()
					return false
				}
				this.$post({
					url: '/api/proposa/xuan',
					params: {
						ids: row.id,
						xuan: row.xuan == 1 ? 2 : 1, //1取消 2选择
					}
				}).then((res) => {
					this.gettable()
				})
			},


			


			look(item) {
				this.rowdata = item
				this.detail_id = item.id
				this.showdetail = true
			},
			goaddnew() {
				this.$router.push('/system/add_zhengji')
			},
			addnew() {
				this.$router.push('/system/swiper_add')
			},
			reset() {
				this.$refs.mysearch.searchinput = ''
				this.$refs.mysearch.searchlei = ''
				this.$refs.mysearch.searchhuiyi = "";
				this.$refs.mysearch.searchhb = ''
				this.$refs.mysearch.searchstatusvalue = ''
				this.$refs.mysearch.searchstatus = ''
				this.$refs.mysearch.searyjstype = '3'
				this.$refs.mysearch.searchbldw = ''
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/proposa/index',
					params: {
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						name: this.$refs.mysearch.searchinput,
						lei_id: this.$refs.mysearch.searchlei,
						sshy: this.$refs.mysearch.searchhuiyi,
						hidtype: this.$refs.mysearch.searchhb,
						blusers:this.$refs.mysearch.searchbldw,
						yjtype:3,
						type:4
					}
				}).then((res) => {
					res.list.forEach((item,index)=>{
						if(item.jstype==1){
							item.type = 11 //待接收
						}
						if(item.jstype==3){
							item.type = 12 //退回
						}
					})
					this.tableData = res.list
					this.$refs.zjlist.total = res.count

					//回显打勾
					let selectedlist = []
					res.list.forEach((item, index) => {
						if (item.xuan != 1) {
							selectedlist.push(item)
						}
					})
					this.$refs.zjlist.toggleSelection(selectedlist);
				})
			},
			deleterow(row) {
				this.$confirm('确认删除该条数据吗？')
					.then(_ => {
						this.$post({
							url: '/admin/banner/delete',
							params: {
								id: row.id
							}
						}).then((res) => {
							this.$message.success('删除成功')
							this.gettable()
						})
					})
					.catch(_ => {});

			},
			showsetrole(row) {
				console.log(row)
				this.$refs.mymx.showmx = true
			},
			changswitch(row) {
				console.log(row)
			}

		},
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {}

	::v-deep .el-table td.el-table__cell {}

	::v-deep .el-table {
		margin-top: 10px;

	}

	::v-deep .el-table__fixed {}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
</style>